import React, { useRef, useMemo } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import NisaConstellation from './NisaConstellation';
import RivetAnimation from './RivetAnimation';
import { rotate } from 'three/webgpu';

function Stars() {
  const starMesh = useRef();

  const starGeometry = useMemo(() => new THREE.BufferGeometry(), []);
  const starMaterial = useMemo(() => new THREE.PointsMaterial({ color: 'pink', size: 0.1 }), []);

  const starPositions = useMemo(() => {
    const positions = [];
    for (let i = 0; i < 2137; i++) {
      positions.push(
        (Math.random() - 0.5) * 200,
        (Math.random() - 0.5) * 200,
        (Math.random() - 0.5) * 200
      );
    }
    return new Float32Array(positions);
  }, []);

  starGeometry.setAttribute('position', new THREE.BufferAttribute(starPositions, 3));

  useFrame(() => {
    starMesh.current.rotation.y += 0.0005;
  
    starMesh.current.material.opacity = 0.5 + Math.sin(Date.now() * 0.002) * 0.5;
  });

  return (
  
      <points ref={starMesh} geometry={starGeometry} material={starMaterial} />
   
  );
}

function SpaceScene() {
  const { camera } = useThree();

  useFrame(() => {
    camera.updateProjectionMatrix();
    
  });

  return (
    <>
      <Stars />
      <RivetAnimation/>
    </>
  );
}

export default SpaceScene;
